
















































import Vue from "vue";
import gql from "graphql-tag";
import Engine from "./Engine";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";
import ContentEditor from "@/components/form/ContentEditor.vue";

interface EnginesData {
  skipQuery: boolean;
  pdb_engine: Engine;
  fueltypes: Array<any>;
}

export default Vue.extend({
  apollo: {
    pdb_engines_by_pk: {
      // GraphQL Query
      query: gql`
        query pdb_engines_by_pk($id: bigint!) {
          pdb_engines_by_pk(id: $id) {
            fuel_type
            body_type
            description
            name
            id
            tag
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          id: this.$route.params.engineID,
        };
      },
      skip() {
        // Skip request if no model id is provided in creation mode
        return this.skipQuery;
      },
      update(data) {
        console.log(data);
        // The returned value will update
        // the vue property 'pingMessage'
        return data.pdb_engines_by_pk;
      },
      result({ data, loading }) {
        if (!loading) {
          console.log(data);
          this.pdb_engine = data.pdb_engines_by_pk;
        }
      },
    },
  },
  data(): EnginesData {
    return {
      skipQuery: true,
      pdb_engine: {} as Engine,
      fueltypes: [
        { name: "Benzin", id: "petrol" },
        { name: "Diesel", id: "diesel" },
      ],
    };
  },
  computed: {
    selectedFuelType: {
      get(): string {
        return `${this.pdb_engine.fuel_type}`;
      },
      set(newVal: string) {
        // const newID = parseInt(newVal);
        this.pdb_engine.fuel_type = newVal;
      },
    },
  },
  created(): void {
    // Get model if model id is provided
    if (this.$route.params.engineID) {
      this.skipQuery = false;
    }
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      const objectToStore: Engine = Object.assign({}, this.pdb_engine);
      delete objectToStore.__typename;
      console.log(objectToStore);

      if (!objectToStore.id) {
        // Create new model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($objects: [pdb_engines_insert_input!]!) {
                insert_pdb_engines(objects: $objects) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              objects: objectToStore,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$router.push("/engines");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      } else {
        // Update model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation(
                $set: pdb_engines_set_input
                $pk: pdb_engines_pk_columns_input!
              ) {
                update_pdb_engines_by_pk(_set: $set, pk_columns: $pk) {
                  id
                }
              }
            `,
            // Parameters
            variables: {
              set: objectToStore,
              pk: { id: objectToStore.id },
            },
          })
          .then((data) => {
            // Result
            console.log(data);

            this.$router.push("/engines");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    cancel(): void {
      this.$router.push("/engines");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
    ContentEditor,
  },
});
